export enum CustomerType {
  AGENT = 'AGENT',
  BROKERAGE = 'BROKERAGE',
  TEAM = 'TEAM',
  MISC = 'MISC',
}

export const CUSTOMER_TYPE_NAME: Record<CustomerType, string> = {
  [CustomerType.AGENT]: 'Agent',
  [CustomerType.BROKERAGE]: 'Brokerage',
  [CustomerType.TEAM]: 'Team',
  [CustomerType.MISC]: 'Miscellaneous',
};
